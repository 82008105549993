$(document).ready(function () {
    $(window).scroll(function () {
        if ($(document).scrollTop()) {
            $('.navbar').addClass('shrink');
            $('.slider2').addClass('shrink');
            $('.page-banner').addClass('shrink');
        } else {
            $('.navbar').removeClass('shrink');
            $('.slider2').removeClass('shrink');
            $('.page-banner').removeClass('shrink');
        }
    });

    function count($this) {
        var current = parseInt($this.html(), 10);
        current = current + 1;

        $this.html(++current);
        if (current > $this.data('count')) {
            $this.html($this.data('count'))
        } else {
            setTimeout(function() { count($this)}, 50 );
        }
    }

    $('.stat-count').each(function () {
        $(this).data('count', parseInt($(this).html(), 10));
        $(this).html('0');
        count($(this));
    })
});

